
import { defineComponent } from 'vue';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonFooter,
  IonButton,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
} from '@ionic/vue';
import PlaceholderIndicator from '@/components/PlaceholderIndicator.vue';
import { SingleRecipePartner, SingleRecipeTag, SingleRecipeCategory } from '@/types/TaxonomyTypes';
import axios from 'axios';
import store from '@/store';
import { RecipeFilter } from '@/types/recipeTypes';
import { tblVars } from '@/variables/environmentVariables';

export default defineComponent({
  name: 'KochenVorrat',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButton,
    IonFooter,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    PlaceholderIndicator,
  },
  data() {
    return {
      // activeRecipePartnerIds: [1],
      activeRecipePartnerData: [] as Array<SingleRecipePartner>,
      tagFilterActive: false,
      activeRecipeTagIds: [] as Array<string>,
      activeRecipeTagData: [] as Array<SingleRecipeTag>,
      categoryFilterActive: false,
      activeRecipeCategoryIds: [] as Array<string>,
      activeRecipeCategoryData: [] as Array<SingleRecipeCategory>,
      activeRecipeFilters: {
        ingredientStock: {
          filterInclude: false,
          ingredients: [393, 351, 49],
        },
        categories: [],
        tags: [],
      } as RecipeFilter,
    };
  },
  // computed: {
  //   joinedCategoryIds() {
  //     const test = this.activeRecipeCategoryIds.join();
  //     return test;
  //   },
  // },
  watch: {
    activeRecipeTagIds: {
      handler(newVal) {
        const numberArray = newVal.map((id: string) => parseInt(id));
        this.activeRecipeFilters.tags = numberArray;
      },
    },
    activeRecipeCategoryIds: {
      handler(newVal) {
        const numberArray = newVal.map((id: string) => parseInt(id));
        this.activeRecipeFilters.categories = numberArray;
      },
    },
    activeRecipeFilters: {
      handler(newVal) {
        this.$eatableStorage.set('recipeFilter', JSON.stringify(newVal));
        store.dispatch('setFilters', newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.getRecipeCategories();
    this.getRecipeTags();

    this.$eatableStorage
      .get('recipeFilter')
      .then(response => {
        if (!response) return;

        const recipeFilterObject = JSON.parse(response);

        this.activeRecipeCategoryIds = recipeFilterObject.categories.map((id: number) => id.toString());
        this.activeRecipeTagIds = recipeFilterObject.tags.map((id: number) => id.toString());
      })
      .catch(error => {
        console.error(error);
      });
  },
  methods: {
    getRecipeTags() {
      axios
        .post(`${tblVars.baseApiUrl}/index.php`, {
          action: 'bigSearch',
          limit: `0,1000000`,
          orderby: 'title',
          searchTable: 'tags',
          searchColumn: 'id',
          sort: 'ASC',
          term: '',
        })
        .then(response => {
          this.activeRecipeTagData = response.data;
          this.tagFilterActive = true;
        })
        .catch(error => {
          console.error(error);
        });
    },
    getRecipeCategories() {
      axios
        .post(`${tblVars.baseApiUrl}/index.php`, {
          action: 'bigSearch',
          limit: `0,1000000`,
          orderby: 'title',
          searchTable: 'categories',
          searchColumn: 'id',
          sort: 'ASC',
          term: '',
        })
        .then(response => {
          this.activeRecipeCategoryData = response.data;
          this.categoryFilterActive = true;
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
});
